String.prototype.capitalizeFirstLetter = function capitalizeFirstLetter() {
  return this.charAt(0).toUpperCase() + this.substr(1);
};

Array.prototype.remove = function (callback) {
  let i = 0;
  while (i < this.length) {
    if (callback(this[i], i)) this.splice(i, 1);
    else ++i;
  }
  return this;
};
