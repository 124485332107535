import rules from './rules';
import feedbacks from './feedbacks';

(function () {
  'use strict';

  window.permissions = {
    // As permissões estão dispostas na ordem que elas devem ser testadas pelo script, devido a perfomance.
    // Dessa forma garante-se que o feedback seguirá a mesma sequência que o fluxo de criação de conta do usuário.
    _available: ['account', 'confirmed_account', 'subscription', 'premium', 'black', 'unlimited'],

    feedbacks: feedbacks,

    start() {
      this.bindClicks();
    },

    prepare(permissions) {
      return permissions.sort((a, b) => {
        return this._available.indexOf(a) - this._available.indexOf(b);
      });
    },

    hasPermissions(permissions, proceed = true, reason = null) {
      this.prepare(permissions).every(permission => {
        if (!rules[permission]) throw new Error(`The rule "${permission}" does not exists!`);

        proceed = rules[permission]();
        reason = (proceed) ? null : permission;

        return proceed;
      });

      return {proceed, reason};
    },

    bindClicks(context = 'body') {
      $('[data-permissions]', $(context))
        .off('click.permissions')
        .on('click.permissions', event => {
          const permissions = $(event.currentTarget)
            .data()
            .permissions
            .split(',');

          const {proceed, reason} = this.hasPermissions(permissions);

          if (!proceed) {
            this.feedbacks[reason](event);
          }
        });
    }
  };

  $(document).on('turbolinks:load', () => {
    permissions.start();
  });

  permissions.start();
})();
