if (typeof window.isGTMStarted === 'undefined') window.isGTMStarted = false;

function bindTurbolinks() {
  document.addEventListener('turbolinks:render', () => {
    let {pathname, search} = window.location;
    if (pathname.charAt(0) === '/') pathname = pathname.substr(1);
    window['dataLayer'].push({'event': 'pageView', 'virtualUrl': pathname + search});
  });
}

function onGTMLoaded() {
  bindTurbolinks();
  window.isGTMStarted = true;
}

if (!window.isGTMStarted && (RAILS_ENV === 'staging' || RAILS_ENV === 'production')) {
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(), event: 'gtm.js'
    });

    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    const dl = l !== 'dataLayer' ? '&l=' + l : '';

    j.async = true;
    j.onload = () => onGTMLoaded();
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', RAILS_ENV === 'production' ? 'GTM-NNF97JX' : 'GTM-NPDX48V');
}
