import './index.scss';

(function () {
  'use strict';

  $(document)
    .off('change.header-menu')
    .on('change.header-menu', '.q-segments-menu--mobile input[data-segment]', e => {
      const $context = $('.q-segments-menu--mobile');
      if (!$context.length) return;

      const input = e.currentTarget;
      const {checked} = input;
      const {segment} = input.dataset;
      const $segmentTabs = $('label[data-segment]', $context);
      const $segmentTab = $(`label[data-segment="${segment}"]`, $context);

      if (checked) {
        $segmentTabs.parent('li').removeClass('hidden');
        $segmentTab.parent('li').addClass('hidden');
      } else {
        $segmentTabs.parent('li').addClass('hidden');
        $segmentTab.parent('li').removeClass('hidden');
      }
    });

  $(document)
    .off('change.segments-toggle-input')
    .on('change.segments-toggle-input', '#segments-toggle-input', e => {
      const $context = $('.q-segments-menu--mobile');
      if (!$context.length) return;

      if (MediaQuery.is('lt_header_lg_breakpoint')) {
        const $body = $('body');
        const {checked} = e.currentTarget;

        if (checked)
          $body.addClass('q-no-scroll q-mobile-menu-open');
        else
          $body.removeClass('q-no-scroll q-mobile-menu-open');
      }
    });
})();
