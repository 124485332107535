import feedbacks from './feedbacks';

(function () {
  'use strict';

  const limits = {

    start(context = document) {
      this.bindClicks(context);
    },

    bindClicks(context = document) {
      $(context)
        .off('click.user_limit', '[data-limit-reached]')
        .on('click.user_limit', '[data-limit-reached]', event => {
          const {limitReached, limitFeedback} = $(event.currentTarget).data();

          if (limitReached === true) {
            feedbacks[limitFeedback](event);
          }
        });
    }
  };

  $(document).on('turbolinks:load', () => {
    limits.start();
  });

  limits.start();
})();
