import ModalManager from 'utils/modal_manager';
import LoginRegister from 'utils/login_register';
import 'components/modals/login_register_modal';
import 'components/modals/session_expired_modal';
import 'components/modals/account_not_confirm_modal';
import 'components/modals/account_limit_free_modal';

const feedbacks = {
  utils: {
    block(event) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    },
    openModal(modal) {
      ModalManager.open(`#${modal}`);
    }
  },

  account(event) {
    LoginRegister.newLoginModal(location.pathname);
    this.utils.block(event);
  },

  confirmed_account(event) {
    this.utils.openModal('js-account-not-confirm-modal');
    this.utils.block(event);
  },

  createNotebookLimit(event) {
    ModalManager.populate('#js-account-limit-free-modal', {
      username: currentUser.name,
      limit: 'criar 05 cadernos'
    });
    this.utils.openModal('js-account-limit-free-modal');
    this.utils.block(event);
  },

  practiceTestLimit(event) {
    ModalManager.populate('#js-account-limit-free-modal', {
      username: currentUser.name,
      limit: 'criar 05 simulados'
    });
    this.utils.openModal('js-account-limit-free-modal');
    this.utils.block(event);
  },

  saveFilterLimit(event) {
    ModalManager.populate('#js-account-limit-free-modal', {
      username: currentUser.name,
      limit: 'criar 05 filtros'
    });
    this.utils.openModal('js-account-limit-free-modal');
    this.utils.block(event);
  },

  resolveQuestionLimit(event) {
    ModalManager.populate('#js-account-limit-free-modal', {
      username: currentUser.name,
      limit: 'resolver 10 questões por dia'
    });
    this.utils.openModal('js-account-limit-free-modal');
    this.utils.block(event);
  },

  printQuestionsLimit(event) {
    ModalManager.populate('#js-account-limit-free-modal', {
      username: currentUser.name,
      limit: '05 impressões'
    });
    this.utils.openModal('js-account-limit-free-modal');
    this.utils.block(event);
  },

  subscription(event) {
    this.utils.openModal('js-not-subscribed-user-modal');
    this.utils.block(event);
  },

  premium(event) {
    this.utils.openModal('js-not-premium-user-modal');
    this.utils.block(event);
  },

  black(event) {
    this.utils.openModal('js-not-black-user-modal');
    this.utils.block(event);
  },

  unlimited(event) {
    this.utils.openModal('js-not-unlimited-user-modal');
    this.utils.block(event);
  }
};

export default feedbacks;
