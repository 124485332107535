import SessionExpired from 'utils/session_expired';
import snakeCase from 'lodash/snakeCase';

export default class AjaxComponentLoader {
  static _request($target, component, params, data, resolve, reject) {
    $.ajax({
      url: `/components/${component}?${$.param(params)}`,
      method: 'POST',
      data,
      success: res => AjaxComponentLoader._success($target, component, res, resolve),
      error: res => AjaxComponentLoader._fail(component, res, reject)
    });
  }

  static _success($target, component, res, cb) {
    if ($target) {
      $target.html(res).addClass('q-component-loaded');
      window.permissions.bindClicks($target);
    }

    if (typeof cb === 'function') cb(res);

    $(document).trigger(`componentLoaded:${component}`, {
      context: $target
    });
  }

  static _fail(component, res, cb) {
    if (res.status === 401) {
      SessionExpired.renewSessionModal(location.pathname);
    } else if (res.status !== 0) {
      AjaxComponentLoader.reportToRollbar(component, res);
      if (typeof cb === 'function') cb(res);
    }
  }

  static load(config) {
    const authenticity_token = $('body').data('authenticity-token');
    const {target, component, asset_key, type, data_type, params = {}} = config;
    const $target = target ? $(target) : null;

    const data = Object.keys(config.data).reduce((cur, key) => {
      cur[snakeCase(key)] = config.data[key];
      return cur;
    }, {});

    params.current_product = data.product_key ? data.product_key : 'concursos';
    let requestData = {
      ...params,
      data,
      asset_key,
      authenticity_token,
      data_type: data_type || 'hash',
      type: type || 'component'
    };

    return new Promise((resolve, reject) => {
      AjaxComponentLoader._request($target, component, params, requestData, resolve, reject);
    });
  }

  static reportToRollbar(component, res) {
    Rollbar.error(
      `AjaxComponentLoader: Erro ao carregar o componente "${component}"`,
      {
        serverStatusText: res.statusText,
        serverStatus: res.status,
        responseText: res.responseText,
        component: component
      }
    );
  }
}
