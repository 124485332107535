/**
 * Arquivos que estão sendo usados em views e não estão em nenhum JS ou CSS
 * devem ser colocados aqui para participarem do build de assets e estarem disponíveis para o comando `asset_pack_path`
 *
 * PREFERENCIALMENTE, se o asset em questão for parte de um componente, favor mover ele para a pasta de componentes e
 * fazer o import em algum arquivo JS do componente
 * Caso o componente tenha somente um SASS ou SCSS, basta fazer o @import
 *
 */
// Header, Logo
import './logo.svg';
import './logo-light.svg';
import './logo/qc.svg';
import './logo/qc-light.svg';
import './logo/logo-qc-direcao.png';
import './logo/logo-qc-direcao-small.png';

// Footer
import './logo-qe/qensino-alone.svg';
import './logo-qe/qensino-horizontal.svg';
import './store/appstore.svg';
import './store/playstore.svg';

// Empty avatars
import './avatar/avatar.svg';

// Color icons
import './color-icons/google-color.svg';

// OpenGraph
import './logo/qcmdpi.png';

// Lessons
import './lessons/premium-lesson.jpg';

// Contests
import './contests/banner-concursos-no-brasil.png';

// Home
import './home/icons/answer-questions.png';
import './home/icons/doubts.png';
import './home/icons/coments-professor.png';
import './home/banner.jpg';
import './home/depoimento1.png';
import './home/depoimento2.png';
import './home/depoimento3.png';
import './home/plan_depoimento1.png';
import './home/plan_depoimento2.png';
import './home/plan_depoimento3.png';
import './home/professor1.jpg';
import './home/professor2.jpg';
import './home/professor3.jpg';
import './home/video.png';
import './home/concursos.png';
import './home/enem.png';
import './home/militar.png';
import './home/oab.png';
import './home/vestibular.png';
import './home/icons/estatisticas.png';
import './home/icons/cadernos.png';
import './home/icons/guia.png';
import './home/icons/simulados.png';
import './home/redacao-perfeita.jpg';
import './home/livraria.jpg';
import './home/ordem-perfeita.jpg';
import './home/banner-pattern.png';
import './home/mancha.png';
import './home/girl.png';
import './home/nomeado.png';
import './home/aprovado.png';

import './isabel.jpg';
import './renato.png';
import './denis.jpg';
import './bethania.jpg';
import './matheus.jpg';
import './alexandre.jpg';
import './pablo.png';
import './leticia.jpg';
import './rafael.jpg';
import './renato.jpg';
import './marcelo.jpg';
import './eulalia.jpg';
import './felipe.jpg';
import './athos.jpg';
import './daniele.jpg';
import './eduardo.jpg';
import './giselle.jpg';
import './elen.jpg';
import './marcelo1.jpg';
import './isis.png';
import './camila.jpg';
import './vitor.jpg';

import './onboarding/bem-vindo.gif';
import './onboarding/modo-noturno.gif';
import './onboarding/responsivo.gif';
import './onboarding/seguranca.gif';
import './onboarding/versao-antiga.gif';

import './img-feedback.png';

// Blog
import './blog/play.svg';
import './blog/trophy.svg';
import './blog/podium.svg';
import './blog/image-default.png';
import './blog/avatar-default.svg';
import './blog/qc-logo.png';

// Partners
import './partners/direcao/logos-parceria-tablet-desktop.png';
import './partners/direcao/bg-ldbanner-desktop.png';
import './partners/direcao/seta-ldbanner-desktop.svg';

// Landing

import './landing-direcao/icons/player.png';
import './landing-direcao/img-section-1.png';
import './landing-direcao/ellipse.png';
import './landing-direcao/inss.png';
import './landing-direcao/rf.png';
import './landing-direcao/tcdf.png';
import './landing-direcao/tcu.png';
import './landing-direcao/sefazdf.png';
import './landing-direcao/tj-am.png';
import './landing-direcao/pc-df.png';
import './landing-direcao/tj-mg.png';
import './landing-direcao/trf-3-regiao.png';
import './landing-direcao/section-2-1.png';
import './landing-direcao/icons/folder.svg';
import './landing-direcao/icons/no-wifi.svg';
import './landing-direcao/icons/savings.svg';
import './landing-direcao/icons/pdf-file.svg';
import './landing-direcao/icons/on-target.svg';
import './landing-direcao/icons/video.svg';
import './landing-direcao/icons/download.svg';
import './landing-direcao/icons/professor.svg';
import './landing-direcao/icons/pdf-icon.svg';

// Guides
import './guides/guia-01.png';
import './guides/guia-02.png';
import './guides/guia-03.png';
import './guides/hero-banner.png';
import './guides/modal-plans.png';

import './xray/seta-bg.svg';
import './xray/waves-bg.svg';

import './badges/activity.svg';
import './badges/notebook-commented.svg';
import './badges/professor.svg';
import './badges/conquista1.svg';
import './badges/conquista2.svg';
import './badges/conquista3.svg';
import './badges/conquista4.svg';
import './badges/conquista5.svg';

import './bf-logo.svg';
import './black-plan-icon.svg';

import './tracks/exclusivo-bf.svg';
import './tracks/senado.png';

//Retrospective
import './retrospective/amoeba-one.png';
import './retrospective/logo-qc.png';
import './retrospective/logo-clean.png';
import './retrospective/retrospective-bg.png';
import './retrospective/download.png';
import './retrospective/vector-second.png';
import './retrospective/facebook.png';
import './retrospective/instagram.png';
import './retrospective/twitter.png';
import './retrospective/ilustration.png';
import './retrospective/vector-unlogged-mobal.png';
import './retrospective/vector-4.png';
import './retrospective/vector-5.png';
import './retrospective/group-29.png';
import './retrospective/group-30.png';
import './retrospective/group-31.png';

