import toastr from 'toastr';

/**
 * Returns an array with error and results of a promise.
 * @param promise
 * @returns Promise([err, res])
 */
global.withError = function (promise) {
  return promise
    .then(res => [null, res])
    .catch(err => [err, null]);
};

/**
 * Global error handler.
 * @param msg Message
 * @param exception Exception
 */
global.handleError = function (msg, exception) {
  toastr.error(msg);
  Rollbar.error(msg, exception);
};
