window.UserTracker = class UserTracker {

  static _queue = [];
  static debug = false;
  static get queue() { return UserTracker._queue; }
  static set queue(v) { UserTracker._queue.push(v); }

  static emit(what = 'event', params = {}) {
    this.queue = {
      fn: what, params: {
        id: Math.random().toString(36).substring(7),
        ...params
      }
    };
    this.process();
  }

  static process() {
    if (!this.enabled()) return false;

    this.queue.forEach(element => {
      this[element.fn](element.params);
    });
  }

  static event({id, eventName = 'default_1', data = {}}) {
    this.send(id, `event.${eventName}`, data);
  }

  static pageVisited({id, pageName = 'default_2', data = {}}) {
    this.send(id, `event.${pageName} visited`, data);
  }

  static productInteraction({id, product_id = 0, event_type = 'view', data = {}}) {
    this.send(id, 'product_event', {product_id, event_type, ...data});
  }

  static enabled() {
    return (!this.debug && typeof userengage === 'function');
  }

  static send(id, key, params) {
    userengage(key, params);
    this._queue = this.queue.filter(item => item.params.id !== id);
  }

};

$(document).on('userDotCom:loaded', () => {
  window.UserTracker.process();
});
document.addEventListener('turbolinks:render', () => window.UserTracker.process());
