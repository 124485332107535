import IMask from 'imask';

(function () {
  'use strict';

  const inputMask = {

    start() {
      this.maskInputs();
    },

    maskInputs() {
      const $inputs = $('input[data-inputmask]');

      $inputs.each((i, input) => {
        const mask = input.dataset.inputmask;

        // specific masks
        switch (mask) {
          case 'YYYY':
            new IMask(input, {
              mask: Number,
              min: 2018,
              max: 2050
            });
            break;
          case 'CREDITCARD':
            new IMask(input, {
              mask: [
                {
                  mask: '0000 000000 00000',
                  startsWith: '34'
                },
                {
                  mask: '0000 000000 00000',
                  startsWith: '37'
                },
                {
                  mask: '0000 0000 0000 0000[ 000]',
                  startsWith: ''
                }
              ],
              dispatch: function (appended, dynamicMasked) {
                var number = (dynamicMasked.value + appended).replace(/\D/g,'');

                return dynamicMasked.compiledMasks.find(function (m) {
                  return number.indexOf(m.startsWith) === 0;
                });
              }
            });
            break;
          default:
            new IMask(input, {mask});
        }

      });
    }
  };

  $(document).on('turbolinks:load', () => {
    inputMask.start();
  });

  inputMask.start();
})();
