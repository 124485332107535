(function ($) {
  'use strict';

  class VendorManager {
    static _createScriptTag(name, url) {
      const script = document.createElement('script');

      script.id = `lazy-script-${name}`;
      script.type = 'text/javascript';
      script.src = url;
      script.async = true;

      return script;
    }

    static _createStyleTag(name, url) {
      const style = document.createElement('link');

      style.id = `lazy-style-${name}`;
      style.type = 'text/css';
      style.rel = 'stylesheet';
      style.href = url;

      return style;
    }

    static _createTags(name, scriptUrl, styleUrl = null) {
      const head = document.querySelector('head');

      const script = VendorManager._createScriptTag(name, scriptUrl);
      window.loadedVendors[name].script = script;

      if (styleUrl) {
        const style = VendorManager._createStyleTag(name, styleUrl);
        window.loadedVendors[name].style = style;

        VendorManager._handleLoadEvents(name, script, style);

        head.insertBefore(style, head.querySelector('link'));
      } else {
        script.onerror = err => console.error(err);
        script.onload = () => VendorManager._execCallbacks(name);
      }

      head.appendChild(script);
    }

    static _execCallbacks(name) {
      const callbacks = window.loadedVendors[name].callbacks;

      $(callbacks).each((i, callback) => {
        callback();
      });

      window.loadedVendors[name].isLoaded = true;
    }

    static _handleLoadEvents(name, script, style) {
      const scriptDeferred = $.Deferred();
      const styleDeferred = $.Deferred();

      script.onload = () => scriptDeferred.resolve();
      script.onerror = err => console.error(err);

      style.onload = () => styleDeferred.resolve();
      style.onerror = err => console.error(err);

      $.when(scriptDeferred, styleDeferred)
        .done(() => {
          VendorManager._execCallbacks(name);
        });
    }

    // TODO: Accept more than one vendor
    static ensure(name, ensureCallback, url = null) {
      const scriptUrl = url ? url : gon.vendor_paths[`vendors/${name}.js`];
      const styleUrl = gon.vendor_paths[`vendors/${name}.css`];

      const ensureCallbackWrapper = () => {
        $(document).trigger('vendor:loaded', name);
        ensureCallback();
      };
      
      window.loadedVendors = window.loadedVendors || {};
      const isTagsAlreadyAdded = (document.querySelector(`#lazy-script-${name}`) || document.querySelector(`#lazy-style-${name}`));
      
      // Skip if already exists
      if (isTagsAlreadyAdded && window.loadedVendors[name] && !window.loadedVendors[name].isLoaded) {
        return window.loadedVendors[name].callbacks.push(ensureCallbackWrapper);
      } else if (window.loadedVendors[name] && window.loadedVendors[name].isLoaded) {
        return ensureCallbackWrapper();
      }
      
      if (!window.loadedVendors[name]) {
        window.loadedVendors[name] = {
          isLoaded: false,
          callbacks: []
        };
      }
      
      // FIXME - sometimes this function is called twice making the callback being pushed twice to the callbacks array above
      window.loadedVendors[name].callbacks.push(ensureCallbackWrapper);

      this._createTags(name, scriptUrl, styleUrl);
    }
  }

  window.vendorManager = VendorManager;
})(jQuery);
