import './index.scss';

export default class Share {
  constructor() {
    this.$context = '.q-share-widget';
    this.bindCopy();
  }

  clip(context) {
    $('.js-cliped', context).addClass('active');

    setTimeout( function() {
      $('.js-cliped', context).removeClass('active');
    }, 300);
  }

  clippingCopyLink(context) {
    const $copyLinkUrl = $('.js-share-copy-link-input', context);
    const itemUrl = $copyLinkUrl[0].value;

    $copyLinkUrl[0].value = itemUrl;

    $copyLinkUrl.select();
    document.execCommand('copy');
  }

  bindCopy() {
    const self = this;
    $('.js-share-copy-link', this.$context).click(function() {
      self.clippingCopyLink(this);
      self.clip(this);
    });
  }
}



