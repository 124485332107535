import 'styles/components/_toastr.scss';
import toastr from 'toastr';

if (!window.toastr) {
  window.toastr = toastr;
  window.toastr.options = {
    'closeButton': true,
    'closeHtml': '<button type="button"><i class="q-icon q-icon-times"></i></button>',
    'debug': false,
    'newestOnTop': false,
    'progressBar': false,
    'positionClass': 'toast-top-right',
    'preventDuplicates': true,
    'onclick': null,
    'showDuration': '5000',
    'hideDuration': '1000',
    'timeOut': '5000',
    'extendedTimeOut': '1000',
    'showEasing': 'swing',
    'hideEasing': 'linear',
    'showMethod': 'fadeIn',
    'hideMethod': 'fadeOut'
  };
}
