// https://github.com/mroderick/PubSubJS
import pubsub from 'pubsub-js';

(function () {
  'use strict';

  delete window.PubSub;
  window.EventManager = pubsub;

})();
