import './index.scss';
import AjaxComponentLoader from 'utils/ajax_component_loader';

class AjaxSidebar {
  constructor(el) {
    this.triggerSelector = '[data-ajax-sidebar]';
    this.$sidebar = $(el);
    this.$toggleInput = $('#ajax-sidebar-toggle-input');
    this.$contentTargetSelector = '.js-sidebar-content';
    this.$contentTarget = $(this.$contentTargetSelector, this.$sidebar);
    this.$title = $('.js-sidebar-title', this.$sidebar);
    this.state = {
      isVisible: false,
      context: {}
    };
    this.bindAll();
  }

  renderTitle() {
    this.$title.empty();
    if (this.state.context.icon) this.$title.append(`<i class="q-icon q-icon-${this.state.context.icon}" />`);
    this.$title.append(`<span>${this.state.context.title}</span>`);
  }

  async renderComponent() {
    this.$contentTarget.empty().append('<div class="q-spinner-container"><i class="q-spinner"/></div>');
    const {componentName: component, type, componentAsset, data} = this.state.context;

    try {
      await AjaxComponentLoader.load({
        component,
        type,
        data,
        target: this.$contentTargetSelector,
        asset_key: `components/${componentAsset}`
      });

      this.$contentTarget.removeClass('error').addClass('loaded');
      EventManager.publish('ajaxSidebar.open');
    } catch (res) {
      this.close();
      this.$contentTarget.removeClass('loaded').addClass('error');
    }
  }

  close() {
    this.$toggleInput.prop('checked', false);
  }

  handleVisibilityChange() {
    this.state.isVisible = this.$toggleInput.is(':checked');
    if (this.state.isVisible) return;
    EventManager.publish('ajaxSidebar.closed');
    this.$contentTarget.html('');
  }

  handleTrigger(event) {
    event.preventDefault();
    const data = {...event.currentTarget.dataset};

    if (data.limitReached === 'true' || data.limitReached === true) return;
    if (this.state.isVisible) return;

    this.$toggleInput.prop('checked', true);

    const {title, icon, componentName, componentAsset} = data;
    this.state.context = {
      title,
      icon,
      componentName,
      componentAsset,
      data,
      type: componentAsset || 'component'
    };

    this.renderTitle();
    this.renderComponent();
  }

  _bindToggleInput() {
    this.$toggleInput.on('change', this.handleVisibilityChange.bind(this));
  }

  _bindTriggers() {
    $(document)
      .off('click.triggerAjaxSidebar')
      .on('click.triggerAjaxSidebar', this.triggerSelector, this.handleTrigger.bind(this));
  }

  bindAll() {
    this._bindToggleInput();
    this._bindTriggers();
  }
}

const start = () => {
  const sidebar = document.getElementById('js-ajax-sidebar');
  if (sidebar && !sidebar.ajaxSidebarInstance) sidebar.ajaxSidebarInstance = new AjaxSidebar(sidebar);
};

$(document).on('turbolinks:load', () => start());
start();
