window.screenBreakpoints = {
  xs: 'screen and (max-width:768px)',
  sm: 'screen and (min-width:768px)',
  lt_sm: 'screen and (max-width:768px)',
  md: 'screen and (min-width:992px)',
  lt_md: 'screen and (max-width:992px)',
  lg: 'screen and (min-width:1200px)',
  lt_lg: 'screen and (max-width:1200px)',
  header_lg_breakpoint: 'screen and (min-width:1024px)',
  lt_header_lg_breakpoint: 'screen and (max-width:1024px)'
};

class MediaQuery {
  static match(query) {
    return window.matchMedia ? window.matchMedia(query).matches : true;
  }

  static is(size) {
    return MediaQuery.match(window.screenBreakpoints[size]);
  }
}

window.MediaQuery = MediaQuery;
