import ModalManager from 'utils/modal_manager';
import AjaxComponentLoader from 'utils/ajax_component_loader';

export default class SessionExpired {
  static renewSessionModal(return_url) {
    AjaxComponentLoader.load({
      component: 'modals/session_expired_modal',
      type: 'component',
      data: {
        return_url: return_url
      }
    }).then((html) => {
      $('body').append(html);
      ModalManager.open('#js-session-expired-modal');
    }).catch( () => {
      Turbolinks.visit(`/conta/entrar?return_url=${return_url}`);
    });
  }
}
