import './index.scss';

(function ($) {
  'use strict';

  const sendEmail = ($button) => {

    Thor.User.send_email_confirmation({user_id: currentUser.id}, {})
      .then(() => {
        $button.parent()
          .html($button.data('already-sent'));
      });
  };

  $(document)
    .off('click.notification_bar_button', '.js-send-email')
    .on('click.notification_bar_button', '.js-send-email', event => {
      event.preventDefault();
      event.stopPropagation();
      const $button = $(event.currentTarget);

      sendEmail($button);
    });
})(jQuery);
