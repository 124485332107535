(function () {
  'use strict';

  if (!window.Storage) return;

  window._odinMemoryStorage = {};

  const originalSetItem = window.Storage.prototype.setItem;
  const originalGetItem = window.Storage.prototype.getItem;
  const originalRemoveItem = window.Storage.prototype.removeItem;

  window.Storage.prototype.setItem = function (key, value) {
    try {
      originalSetItem.bind(this)(key, value);
    } catch (ex) {
      window._odinMemoryStorage[key] = value;
    }
  };

  window.Storage.prototype.getItem = function (key) {
    if (window._odinMemoryStorage.hasOwnProperty(key)) return window._odinMemoryStorage[key];
    return originalGetItem.bind(this)(key);
  };

  window.Storage.prototype.removeItem = function (key) {
    if (window._odinMemoryStorage.hasOwnProperty(key)) {
      delete window._odinMemoryStorage[key];
      return;
    }

    originalRemoveItem.bind(this)(key);
  };

})();
