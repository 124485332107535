(function ($) {
  'use strict';

  function greaterThan(a, b) {
    if (isNaN(parseInt(a))) return a > b;
    return parseInt(a) > parseInt(b);
  }

  function sortParams(params) {
    return $.param(params.sort((a, b) => {
      if ((a.name === b.name) && (a.value === b.value)) return 0;
      if (a.name > b.name) return 1;
      if ((a.name === b.name) && (greaterThan(a.value, b.value))) return 1;
      return -1;
    }));
  }

  function prepareQuery(params) {
    const filteredParams = params.filter(p => !!p.value);
    return sortParams(filteredParams);
  }

  $(document).on('submit', '[data-turbolinks-form="true"]', e => {
    e.preventDefault();

    const $form = $(e.currentTarget);
    const query = prepareQuery($form.serializeArray());
    const url = $form.attr('action');

    if ($form.data('turbolinks-scroll'))
      Turbolinks.persistScroll();

    Turbolinks.visit(`${url}?${query}`);
  });
})(jQuery);
