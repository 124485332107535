(function () {
  'use strict';

  function recalculateHorizontalDirection($dropdownMenu) {
    const width = $dropdownMenu.outerWidth();
    const offset = $dropdownMenu.offset();
    const screenWidth = $(window).innerWidth();

    if ((offset.left + width) > screenWidth) {
      $dropdownMenu.addClass('pull-right');
    }
  }

  // Keeps Dropdown Menu
  $(document)
    .off('click.bsdropdown', '.dropdown-menu.dropdown-menu-keepopen')
    .on('click.bsdropdown', '.dropdown-menu.dropdown-menu-keepopen', function (e) {
      $(this).parent().is('.open') && e.stopPropagation();
    });

  // Prevents Dropdown Menu from collapse with screen horizontal size
  $(document)
    .off('shown.bs.dropdown.bsdropdown', '.dropdown')
    .on('shown.bs.dropdown.bsdropdown', '.dropdown', e => {
      const $dropdownMenu = $('.dropdown-menu', $(e.currentTarget));
      recalculateHorizontalDirection($dropdownMenu);
    });
})(jQuery);
