import 'styles';
import 'images';

import 'utils/font_loader';
import 'utils/turbolinks';

/* Utils */
import 'utils/global';
import 'utils/prototype';
import 'utils/storage';
import 'utils/permissions/permissions';
import 'utils/permissions/limits';
import 'utils/input_mask';
import 'utils/bootstrap';
import 'utils/blazy';
import 'utils/thor';
import 'utils/lazy';
import 'utils/icontains';
import 'utils/form_clear';
import 'utils/vendor_manager';
import 'utils/event_manager';
import 'utils/media_query';
import 'utils/bs_dropdown';
import 'utils/current_user';
import 'utils/stored_filters';
import 'utils/turbolinks_form';
import 'utils/statements';
import 'utils/toggle_input';
import 'utils/scroll_to';

/* Global Components */
import 'components/logo';
import 'components/header';
import 'components/footer';
import 'components/menu';
import 'components/pagination';
import 'components/share';
import 'components/breadcrumb';
import 'components/notification_bar';
import 'components/campaigns/widgets/top_bar_widget';
import 'components/ajax_sidebar';
import 'components/sticky_cta_menu';
import 'components/outdated_browser';
import 'components/modals/not_premium_user_modal';


/* External Services */
import 'utils/trackers';
import 'utils/trackers/user';

document.addEventListener('DOMContentLoaded', () => window.notifyApplicationLoaded());
