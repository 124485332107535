
// Tooltips
function initTooltips() {
  $(document)
    .tooltip({
      selector: '[data-toggle="tooltip"]'
    });
}

initTooltips();

$(document).on('turbolinks:load', () => {
  initTooltips();
});
