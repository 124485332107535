import Turbolinks from 'turbolinks';
import './scroll';

function handleJQueryEvents() {
  // Disabling old event listeners.
  window.odinListeners = [];
  document.addEventListener('turbolinks:before-visit', () => {
    window.odinListeners.forEach(listener => {
      let {el, args} = listener;
      $(el).off(...args);
    });
    window.odinListeners = [];
  });

  // Proxying jQuery .on to store all event listeners of jQuery.
  const _on = $.prototype.on;
  $.prototype.on = function () {
    let el = this.get(0);
    if (el && el !== document && el !== window)
      window.odinListeners.push({el, args: arguments});

    return _on.call(this, ...arguments);
  };
}

(function () {
  'use strict';

  // Sending Is-Turbolinks-Request header in every Turbolinks calls.
  document.addEventListener('turbolinks:request-start', function (event) {
    window.isTurbolinksLoading = true;
    const xhr = event.data.xhr;
    xhr.setRequestHeader('Is-Turbolinks-Request', true);
  });

  document.addEventListener('turbolinks:load', function () {
    window.isTurbolinksLoading = false;
  });

  handleJQueryEvents();
  Turbolinks.start();
})();
