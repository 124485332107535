import 'utils/toastr';
import map from 'lodash/map';
import {handleApiError} from 'utils/api_error_handler';

const StoredFilters = {

  segmentFilters: {
    8: {
      _availableFilters: {
        q: 'keyword',
        by_examining_board: 'examining_boards_ids',
        by_institute: 'institutes_ids',
        by_year: 'years',
        by_states: 'states_ids',
        by_situation: 'situations_ids'
      },
      _reverseTranslation: {
        keyword: 'q',
        examining_boards_ids: 'by_examining_board',
        institutes_ids: 'by_institute',
        years: 'by_year',
        states_ids: 'by_states',
        situations_ids: 'by_situation'
      }
    },
    7: {
      _availableFilters: {
        q: 'keyword',
        discipline_ids: 'disciplines_ids',
        subject_ids: 'subjects_ids',
        examining_board_ids: 'examining_boards_ids',
        institute_ids: 'institutes_ids',
        scholarity_ids: 'scholarities_ids',
        has_professor_commentaries: 'commented_by_professor',
        has_commentaries: 'commented_by_users',
        with_my_commentaries: 'commented',
        with_my_annotations: 'annotated',
        exclude_from_notebooks: 'excluding_my_notebooks',
        exclude_from_practice_tests: 'exclude_from_practice_tests',
        knowledge_area_ids: 'knowledge_areas_ids',
        job_ids: 'jobs_ids',
        publication_year: 'years',
        administrative_level_ids: 'administrative_levels_ids',
        modality_ids: 'modalities_ids',
        difficulty: 'difficulty_levels_ids',
        my_questions: 'my_questions',
        exclude_nullified: 'exclude_nullified',
        exclude_outdated: 'exclude_outdated'
      },
      _reverseTranslation: {
        keyword: 'q',
        disciplines_ids: 'discipline_ids',
        subjects_ids: 'subject_ids',
        examining_boards_ids: 'examining_board_ids',
        institutes_ids: 'institute_ids',
        scholarities_ids: 'scholarity_ids',
        commented_by_professor: 'has_professor_commentaries',
        commented_by_users: 'has_commentaries',
        commented: 'with_my_commentaries',
        annotated: 'with_my_annotations',
        excluding_my_notebooks: 'exclude_from_notebooks',
        exclude_from_practice_tests: 'exclude_from_practice_tests',
        knowledge_areas_ids: 'knowledge_area_ids',
        jobs_ids: 'job_ids',
        years: 'publication_year',
        administrative_levels_ids: 'administrative_level_ids',
        modalities_ids: 'modality_ids',
        difficulty_levels_ids: 'difficulty',
        my_questions: 'my_questions',
        exclude_nullified: 'exclude_nullified',
        exclude_outdated: 'exclude_outdated'
      }
    },
    3: {
      _availableFilters: {
        q: 'keyword',
        by_institute: 'institutes_ids',
        by_examining_board: 'examining_boards_ids',
        application_year: 'years',
        by_job: 'jobs_ids',
        by_scholarity: 'scholarities_ids',
        by_administrative_level: 'administrative_levels_ids',
        by_knowledge_area: 'knowledge_areas_ids',
        online: 'online'
      },
      _reverseTranslation: {
        keyword: 'q',
        institutes_ids: 'by_institute',
        examining_boards_ids: 'by_examining_board',
        years: 'application_year',
        jobs_ids: 'by_job',
        scholarities_ids: 'by_scholarity',
        administrative_levels_ids: 'by_administrative_level',
        knowledge_areas_ids: 'by_knowledge_area',
        online: 'online'
      }
    }
  },

  save(name, product, segment, serialized, $button) {
    this.serialized = serialized;
    const source_project = 1;
    Thor.StoredFilter
      .create({}, {
        data: this.prepareRequest(name, product, segment, source_project)
      })
      .then(() => {
        toastr.success('Filtro salvo com sucesso!');
        if ($button) {
          $button.attr('disabled', true);
        }
      })
      .fail(({response}) => {
        const errors = response?.responseJSON?.errors;

        if (errors?.name) {
          handleApiError(errors.name, response);
        } else if (errors?.user_id && errors?.user_id[0]) {
          handleApiError(errors.user_id[0], response);
        } else {
          handleApiError('Ops! Não foi possível criar o seu filtro nesse momento!', response);
        }
      });
  },

  prepareQuery(filters) {
    const queryStringKeys = {};

    for (const key in filters) {
      const correctKey = this.segmentFilters[filters.segment_id]._reverseTranslation[key];
      let value = filters[key];

      if (correctKey && value && String(value).length > 0) {
        if (Array.isArray(value) && typeof value[0] === 'object') {
          value = map(value, 'id');
        }
        queryStringKeys[correctKey] = value;
      }
    }

    return $.param(queryStringKeys);
  },

  prepareRequest(name, product, segment_id, source_project) {
    return {
      product,
      segment_id,
      stored_filter: $.extend(this.translateParams(segment_id), {name}),
      source_project
    };
  },

  translateParams(segment_id, params = this.serialized) {
    let translatedParams = {};

    for (const key in params) {
      const value = params[key];
      const correctKey = this.segmentFilters[segment_id]._availableFilters[key];

      if (correctKey && value && (typeof value === 'boolean' || value.length > 0)) {
        translatedParams[correctKey] = value;
      }
    }

    return translatedParams;
  }

};

export default StoredFilters;
