import './index.scss';

(function ($) {
  'use strict';

  const sendEmail = ($button) => {

    Thor.User.send_email_confirmation({user_id: currentUser.id}, {})
      .then(() => {
        $button.parent()
          .html($button.data('already-sent'));
      });
  };

  $(document).on('click', '.js-btn-resend-email', event => {
    event.preventDefault();
    event.stopPropagation();
    const $button = $(event.currentTarget);

    sendEmail($button);
  });

})(jQuery);


