(function ($) {
  'use strict';

  if (!$.fn.clearForm) {
    $.fn.clearForm = function (clearHidden = false) {
      const $form = $(this);

      if (!$form.is('form')) return $form;

      let $inputs = $form.find('input, select, textarea');

      if (!clearHidden)
        $inputs = $inputs.not('[type="hidden"]');

      // Cleaning Toggles
      $form.find('input[type="radio"]:checked, input[type="checkbox"]:checked')
        .prop('checked', false)
        .trigger('change');
      
      // Cleaning Inputs
      $inputs
        .not('input[type="radio"], input[type="checkbox"]')
        .val('', true)
        .trigger('change')
        .focus();
      
      return $form;
    };
  }

})(jQuery);
