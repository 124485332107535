import './index.scss';

(function ($) {
  function init() {
    $('#js-footer-collapse-button').on('click', () => {
      $('#js-footer').toggleClass('expand');
    });
  }

  init();
})(jQuery);
