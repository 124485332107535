const rules = {

  utils: {
    bodyData() {
      return $('body').data();
    }
  },

  account() {
    return this.utils.bodyData().isUserSignedIn;
  },

  confirmed_account() {
    return this.utils.bodyData().isUserEmailConfirmed;
  },

  subscription() {
    return this.utils.bodyData().isUserSubscribed;
  },

  premium() {
    return this.utils.bodyData().isUserPremium || this.black() || this.unlimited(); // FIXME MAYKE PLEASE
  },

  black() {
    return this.utils.bodyData().isUserBlack;
  },

  unlimited() {
    return this.utils.bodyData().isUserUnlimited;
  }

};

export default rules;
