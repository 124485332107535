if (typeof window.isUserDotComStarted === 'undefined') window.isUserDotComStarted = false;

function getUserData() {
  const user = $('#js-current-user').get(0);
  if (!user) return {};

  const {email, name, id, created_at, subscriptionStatus, login, type} = user.dataset;
  return {email, name, created_at, login, user_id: parseInt(id), subscription: subscriptionStatus, user_type: type};
}

function hit() {
  const userData = getUserData();

  UE.pageHit({
    ...userData,
    ...{apiKey: 'UWbeJ9'}
  });
}

function bindTurbolinks() {
  document.addEventListener('turbolinks:render', () => hit());
}

function onUserDotComLoaded() {
  bindTurbolinks();
  hit();
  window.isUserDotComStarted = true;
  $(document).trigger('userDotCom:loaded');
}

if (!window.isUserDotComStarted) {
  (function (d, s) {
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);

    j.setAttribute('data-cfasync', false);
    j.onload = () => onUserDotComLoaded();
    j.src = '//qconcursos.user.com/widget.js';
    f.parentNode.insertBefore(j, f);
  })(document, 'script');
}
