import Blazy from 'blazy';

window.initBlazy = function initBlazy() {
  if (global.blazyInstance) {
    global.blazyInstance.revalidate();
  } else {
    global.Blazy = Blazy;
    global.blazyInstance = new Blazy({
      offset: 300
    });
  }
};

$(document).on('shown.bs.collapse', () => initBlazy());
$(document).on('shown.bs.tab', () => initBlazy());
$(document).on('shown.bs.modal', () => initBlazy());
$(document).on('turbolinks:render', () => {
  setTimeout(() => initBlazy(), 100);
});
$(document).ready(() => initBlazy());

initBlazy();
setTimeout(() => initBlazy(), 1500);
