(function ($) {
  'use strict';

  $(document).on('click', 'a[data-toggle="scroll-to"]', e => {
    e.preventDefault();
    let $clickTarget = $(e.target);
    if (!$clickTarget.is('a'))
      $clickTarget = $clickTarget.parents('a[data-toggle="scroll-to"]');

    const id = $clickTarget.attr('href');
    const $target = $(id);
    if (!$target.length) return;

    $('html, body').stop().animate({
      scrollTop: $(id).offset().top
    }, 1000);
  });
})(jQuery);
