(function ($) {
  'use strict';

  $(document).on('change', '.checkbox--button > input', function () {
    $(this).parent('.checkbox--button').toggleClass('checkbox--button-checked');
  });
  $(document).on('change', '.radio--button > input', function () {
    $(this).parent('.radio--button').toggleClass('radio--button-checked');
  });

})(jQuery);
