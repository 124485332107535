import '../serialize_object';

class ModalManager {
  static bindForm($modal, callback) {
    const $form = $('form', $modal);
    if (!$form.length) return;

    $form
      .off('submit.form')
      .on('submit.form', e => {
        e.preventDefault();
        const formData = $form.serializeObject();
        $modal.modal('hide');
        callback(formData, $(e.currentTarget));
        $form.clearForm();
      });
  }

  static populate(selector, data) {
    const $modalElement = selector.jquery ? selector : $(selector);

    for (const itemName in data) {
      const $element = $modalElement.find(`[data-modal-${itemName}]`);
      const value = data[itemName];

      if ($element.is('button')) value ? $element.show() : $element.hide();
      if ($element.attr('value') !== undefined) {
        $element.val(value);
      } else {
        $element.html(value);
      }
    }

  }

  static bindButtons($modal, callback) {
    $('#js-ok-btn', $modal)
      .off('click')
      .on('click', e => {
        e.preventDefault();
        $modal.modal('hide');

        if (callback && typeof callback === 'function')
          callback(true);
      });

    $('#js-cancel-btn', $modal)
      .off('click')
      .on('click', e => {
        e.preventDefault();
        $modal.modal('hide');

        if (callback && typeof callback === 'function')
          callback(false);
      });
  }

  static open(selector, callback) {
    const $modalElement = selector.jquery ? selector : $(selector);
    const $modal = $modalElement.modal();

    const isCallbackEnabled = callback && typeof callback === 'function';

    if (isCallbackEnabled)
      ModalManager.bindForm($modal, callback);

    ModalManager.bindButtons($modal, isCallbackEnabled ? callback : null);

    $modal.modal('show');

    return $modal;
  }
}

export default ModalManager;
