import './index.scss';

(function ($) {
  'use strict';

  if (!$.fn.lazyReady) {
    $.fn.lazyReady = function () {
      const element = this;
      element.addClass('q-lazy-ready');
      element.removeClass('q-lazy');
      return element;
    };
  }

  // Fallback for lazyReady
  $(document).on('turbolinks:load', () => {
    const $lazy = $('.q-lazy');
    setTimeout(() => {
      if (!$lazy.hasClass('q-lazy-ready')) {
        $lazy.addClass('q-lazy-ready');
        $lazy.removeClass('q-lazy');
      }
    }, 1500);
  });
})(jQuery);
