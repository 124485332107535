import 'components/user/avatar';
import 'components/header_mobile_menu'
import './index.scss';
import 'images/tracks/direcao-icon.svg';
import 'images/tracks/direcao.svg';

(function ($) {
  function init() {
    function handleOutsideClick(event, $element, $Toggle, $ToggleInput) {
      if ($(event.target).closest($element).length === 0 &&
        $(event.target).closest($Toggle).length === 0 &&
        $(event.target).closest($ToggleInput).length === 0 &&
        ($element.length && !$element.is(':hover'))) {
        $ToggleInput.prop('checked', false);
      }
    }

    $(document)
      .off('click.header')
      .on('click.header', event => {
        const $segmentsMenu = $('#js-segments-menu');
        const $segmentsMenuToggle = $('.js-segments-menu-toggle');
        const $segmentsMenuToggleInput = $('#segments-toggle-input');

        const $search = $('#js-header-search');
        const $searchToggle = $('.js-header-search-toggle');
        const $searchToggleInput = $('#search-toggle-input');

        handleOutsideClick(event, $segmentsMenu, $segmentsMenuToggle, $segmentsMenuToggleInput);
        handleOutsideClick(event, $search, $searchToggle, $searchToggleInput);
      });
  }

  function attachEvent() {
    $('.q-ils-link').on('click', function (e) {
      UserTracker.emit('event', {
        eventName: 'Dashboard Table Visited'
      });
    });

    if (MediaQuery.is('lt_header_lg_breakpoint')) {
      $('#js-segments-menu .q-segments-menu--desktop a').on('click', e => {
        const $link = $(e.currentTarget);

        UserTracker.emit('event', {
          eventName: 'Mobile Menu Click',
          data: {
            product: $link.parents('.q-col').first().find('label.q-segment').text().trim().toUpperCase(),
            item: $link.text().trim(),
            href: $link.attr('href'),
            version: 'old'
          }
        });
      });

      $('#js-segments-menu .q-segments-menu--mobile a').on('click', e => {
        const $link = $(e.currentTarget);

        UserTracker.emit('event', {
          eventName: 'Mobile Menu Click',
          data: {
            product: $link.parents('.q-segments-mobile-menu-tab-scroll').data('product').toUpperCase(),
            item: $link.text().trim(),
            href: $link.attr('href'),
            version: 'new'
          }
        });
      });
    }
  }

  $(document).on('turbolinks:load', () => {
    init();
    attachEvent();
  });

})(jQuery);
