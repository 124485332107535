(function ($) {
  $.fn.serializeObject = function (castValues = true, alwaysReturnKeys = false) {
    let self = this,
      json = {},
      push_counters = {},
      patterns = {
        'validate': /^[a-zA-Z][a-zA-Z0-9_]*(?:\[(?:\d*|[a-zA-Z0-9_]+)\])*$/,
        'key': /[a-zA-Z0-9_]+|(?=\[\])/g,
        'push': /^$/,
        'fixed': /^\d+$/,
        'named': /^[a-zA-Z0-9_]+$/
      };

    this.isJsonString = function (str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    };

    this.castValues = (obj) => {
      for (const key in obj) {
        if (!obj.hasOwnProperty(key)) continue;

        let value = obj[key];

        if (typeof value === 'undefined' || value === null || value.toString().length === 0) continue;

        if (typeof value === 'object') {
          value = this.castValues(value);
        } else if (value === 'true') {
          value = true;
        } else if (value === 'false') {
          value = false;
        } else if (!isNaN(value)) {
          value = parseFloat(value);
        } else if (this.isJsonString(value)) {
          value = JSON.parse(value);
        }

        obj[key] = value;
      }

      return obj;
    };

    this.build = function (base, key, value) {
      if (alwaysReturnKeys || (value && value.toString().length))
        base[key] = value;
      return base;
    };

    this.push_counter = function (key) {
      if (push_counters[key] === undefined) {
        push_counters[key] = 0;
      }
      return push_counters[key]++;
    };

    $.each($(this).serializeArray(), function () {

      // skip invalid keys
      if (!patterns.validate.test(this.name)) {
        return;
      }

      let k,
        keys = this.name.match(patterns.key),
        merge = this.value,
        reverse_key = this.name;

      while ((k = keys.pop()) !== undefined) {

        // adjust reverse_key
        reverse_key = reverse_key.replace(new RegExp('\\[' + k + '\\]$'), '');

        if (k.match(patterns.push)) {
          merge = self.build([], self.push_counter(reverse_key), merge);
        } else if (k.match(patterns.fixed)) {
          merge = self.build([], k, merge);
        } else if (k.match(patterns.named)) {
          merge = self.build({}, k, merge);
        }
      }

      json = $.extend(true, json, merge);
    });

    return castValues ? this.castValues(json) : json;
  };
})(jQuery);
