import 'utils/toastr';
import SessionExpired from 'utils/session_expired';

export function handleApiError(message, response) {
  if (response?.status === 401) {
    SessionExpired.renewSessionModal(location.pathname);
  } else {
    toastr.error(message || 'Ocorreu um erro, tente novamente.');

    if (!response?.errors) return;
    const errors = Object.keys(response.errors);

    if (errors.length) {
      const errorKey = errors[0];
      const errorMsgs = response.errors[errorKey];
      const errorMsg = (errorMsgs.length && errorMsgs[0]) || '';

      Rollbar.error(
        `API response error${errorMsg && `: ${errorKey} ${errorMsg}`}.`,
        {
          status: response.status,
          statusText: response.statusText,
          responseText: response.responseText
        }
      );
    }
  }
}
