import Turbolinks from 'turbolinks';

(function () {
  'use strict';

  const $document = $(document);
  const initialScrollState = {
    top: null,
    anchor: null,
    animate: false
  };

  if (typeof Turbolinks.scrollState === 'undefined')
    Turbolinks.scrollState = {...initialScrollState};

  Turbolinks.resetScrollState = function () {
    Turbolinks.scrollState = {...initialScrollState};
  };

  Turbolinks.persistScroll = function (animate = false) {
    Turbolinks.scrollState.animate = animate;
    Turbolinks.scrollState.top = $document.scrollTop();
  };

  $document
    .on('turbolinks:click', e => {
      if (typeof e.target.dataset.turbolinksScroll !== 'undefined')
        Turbolinks.persistScroll();

      if (typeof e.target.dataset.turbolinksAnimateScroll !== 'undefined')
        Turbolinks.scrollState.animate = true;
    })
    .on('turbolinks:render', () => {
      if (Turbolinks.scrollState.top)
        window.scrollTo(0, Turbolinks.scrollState.top);

      Turbolinks.resetScrollState();
    });

  // Intercepting Turbolinks scrollTo to enable scroll animation
  const _scrollToAnchor = Turbolinks.controller.scrollToAnchor;
  Turbolinks.controller.scrollToAnchor = function (id) {
    if (Turbolinks.scrollState.animate)
      $('html, body').animate({
        scrollTop: $(`#${id}`).offset().top
      });
    else
      return _scrollToAnchor.call(Turbolinks.controller, ...arguments);
  };
})();
