import FontFaceObserver from 'fontfaceobserver/fontfaceobserver';

const siteFont = new FontFaceObserver('Open Sans');
const iconsFont = new FontFaceObserver('qicons');

if (RAILS_ENV === 'development') {
  document.documentElement.classList.add('q-site-font-loaded');
  document.documentElement.classList.add('q-icon-font-loaded');
} else {
  siteFont.load().then(() => {
    document.documentElement.classList.add('q-site-font-loaded');
  }).catch(() => {
    document.documentElement.classList.add('q-site-font-loaded');
  });
  iconsFont.load().then(() => {
    document.documentElement.classList.add('q-icon-font-loaded');
  }).catch(() => {
    document.documentElement.classList.add('q-icon-font-loaded');
  });
}
